<template lang="pug">
.status-tag(:status="normalized" :class="color") {{ transformType | cap }}
</template>

<script>
import { snakeCase } from 'lodash'
import { PRODUCT_AVAILABILITY } from '~/settings'

export default {
  name: 'StatusTag',

  props: {
    type: {
      type: String,
      default: 'None'
    },
    color: {
      type: [Object, String],
      default: ''
    }
  },

  computed: {
    normalized () {
      return snakeCase(this.transformType)
    },

    transformType () {
      const parsed = this.type?.split(':') || [this.type]
      const type = parsed.pop()
      const prefix = parsed.shift()

      switch (type) {
        case 'out_of_stock':
        case PRODUCT_AVAILABILITY.COMING_SOON:
          return 'Out of Stock'
        case 'lp_review':
          return 'LP Review'
        case 'under_review':
          return 'Under Review'
        case 'pod_planned':
          return { inbound_inventory: 'Requested' }[prefix] || type
        case 'submitted':
          return { inbound_inventory: 'Confirmed' }[prefix] || type
        default:
          return type
      }
    }
  }
}
</script>

<style lang="scss">
.status-tag {
  $symbol-size: 5px;
  $grey: #ccc;
  display: inline-block;
  white-space: nowrap;
  font-size: 1em;
  font-weight: normal;
  color: black;

  &.preferred {
    color: #fa3;
  }
  &.mandy {
    color: #e24960;
  }
  &.bordered {
    border: 1px solid #dcdfe6;
    background-color: white;
    border-radius: 4px;
    padding: 2px 6px;
  }
  &:before {
    content: '';
    display: inline-block;
    width: $symbol-size;
    height: $symbol-size;
    margin-right: $symbol-size * 1.5;
    border: 2px solid transparent;
    border-radius: $symbol-size * .3;
    background-color: transparent;
  }
  &[status=pending]:before {
    border-radius: $symbol-size;
    border-color: $--color-primary;
  }
  &[status=in_progress]:before {
    border-color: $--color-primary;
  }
  &[status=unpaid]:before {
    border-color: $grey;
    background-color: $grey;
  }
  &[status=partially_paid]:before {
    border-color: $--color-warning;
    background-color: $--color-warning;
  }
  &[status=declined]:before {
    border-color: $--color-danger;
    background-color: $--color-danger;
  }
  &[status=paid]:before,
  &[status=fulfilled]:before,
  &[status=in_stock]:before {
    border-color: $--color-primary;
    background-color: $--color-primary;
  }
  &[status=active]:before,
  &[status=delivered]:before {
    border-color: $--color-primary;
    border-radius: $symbol-size;
    background-color: $--color-primary;
  }
  &[status=inactive]:before {
    border-color: $grey;
    background-color: $grey;
    border-radius: $symbol-size;
  }
  &[status=not_available]:before {
    border-color: $grey;
    background-color: $grey;
    border-radius: $symbol-size;
  }
  &[status=draft]:before {
    border-color: $grey;
    border-radius: $symbol-size;
  }
  &[status=unconfirmed]:before {
    border-color: $grey;
    background-color: $grey;
  }
  &[status=coming_soon]:before,
  &[status=out_of_stock]:before {
    border-color: $--color-blue;
    background-color: $--color-blue;
  }
  &[status=sold_out]:before {
    border-color: $--color-danger;
    background-color: $--color-danger;
  }
  &[status=expected_inventory]:before {
    border-color: $--color-warning;
    background-color: $--color-warning;
    border-radius: $symbol-size;
  }
  // credit memo
  &[status=not_used]:before,
  &[status=not_applied]:before {
    background-color: $--color-blue;
  }
  &[status=partially_applied]:before {
    background-color: transparent;
    width: 0;
    height: 0;
    font-size: 0;
    border: $symbol-size solid lighten($--color-success, 30);
    border-bottom-color: $--color-success;
    border-right-color: $--color-success;
  }
  &[status=resolved]:before,
  &[status=used]:before,
  &[status=fully_applied]:before,
  &[status=completed]:before {
    background-color: $--color-success;
  }
  &[status=canceled]:before {
    background-color: #ccc;
  }
  &[status=expired]:before {
    background-color: $--color-danger;
  }
  &[status=open]:before,
  &[status=submitted]:before,
  &[status=requested]:before,
  &[status=upcoming]:before {
    border-color: #00b6cb;
    background-color: #00b6cb;
  }
  &[status=approved]:before {
    border-color: $--color-primary;
    background-color: $--color-primary;
  }
  &[status=processed]:before {
    border-color: $--color-danger;
    background-color: $--color-danger;
  }
  &[status=pod_planned]:before,
  &[status=confirmed]:before {
    border-color: #f76c01;
    background-color: #f76c01;
  }
  &[status=received]:before {
    border-color: #1aa880;
    background-color: #1aa880;
  }
  &[status=completed]:before {
    border-color: $--color-success;
  }
  &[status=lp_review]:before,
  &[status=under_review]:before {
    border-color: $--color-warning;
    background-color: $--color-warning;
  }
}
</style>
