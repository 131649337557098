import Vue, { set as vset } from 'vue'
import { each } from 'lodash'

export const state = () => ({
  // pref item starts with $ will not be clear after logout
  all: {
    lastBrandId: null,
    hideBuyerAppInvitation: false
  }
})

export const getters = {
  all: s => s.all || {},

  byKey: (s, g) => (key, fallback) => {
    return g.all[key] === undefined
      ? fallback
      : g.all[key]
  }
}

export const mutations = {
  update (state, payload = {}) {
    each(payload, (value, key) => {
      vset(state.all, key, value)
    })
  },

  clear (state, key) {
    Vue.delete(state.all, key)
  },

  reset (state) {
    each(state.all, (val, key) => {
      if (!key.startsWith('$')) {
        Vue.delete(state.all, key)
      }
    })
  }
}
