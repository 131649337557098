<template lang="pug">
.product-variant-stamp(:class="{selected, selectable: !readOnly}")
  component.flex(
    :is="asLink ? 'nuxt-link' : 'div'"
    :to="$links.product({id: data.product_id, name: data.product_name, variant: name, variantId, brand: data.brand_name})"
    v-click="() => asLink && $amplitude.trackProduct(trackProps)")
    .image(v-if="showImage")
      nuxt-link.linked(
        v-if="!asLink && clickableImage"
        :to="$links.product({id: data.product_id, name: data.product_name, variant: name, variantId, brand: data.brand_name})"
        :title="name"
        v-click="() => $amplitude.trackProduct(trackProps)")
        .contain(v-image="imageUrl")
      .contain(v-image="imageUrl" v-else)
    .preview
      .promotion(v-if="showPromotion && promotions.length")
        promotion-tag(:type="promotions[0].type | promotionType" showPopover :variants="[{ ...data, imageUrl: imageUrl, name: name}]")
      .brand(v-if="showBrand")
        strong
          nuxt-link(
            v-if="!asLink && showBrandUrl"
            :to="$links.brand({id: data.brand_id, name: data.brand_name})"
            v-text="data.brand_name"
            :title="data.brand_name"
            v-click="() => $amplitude.trackBrand(trackProps)")
          span(v-else v-text="data.brand_name")
      .product(v-if="showProduct")
        strong
          nuxt-link.linked(
            v-if="!asLink && showProductUrl"
            v-text="data.product_name"
            :to="$links.product({id: data.product_id, name: data.product_name, brand: data.brand_name})"
            :title="data.product_name"
            v-click="() => $amplitude.trackProduct(trackProps)")
          span(v-else v-text="data.product_name")
      .variant(v-if="showVariant")
        nuxt-link.linked(
          v-if="!asLink && showVariantUrl"
          :to="$links.product({id: data.product_id, name: data.product_name, variant: name, variantId, brand: data.brand_name})"
          :title="name"
          v-click="() => $amplitude.trackProduct(trackProps)") {{ name }}
        span(v-else) {{ name }}
      .variant-regions(v-if="showVariantRegions")
        .region-item(v-for="i, k in data.variants_regions" :key="k")
          .blocks
            .edt-piece.region {{ i.region_name }}
            .edt-piece.original-price(:class="{ 'line-through': data.promotion }") {{ i.case_price_cents | cents }}
            .edt-piece.discount-price(v-if="data.promotion") {{ i.case_price_cents | promotionPrice(data.promotion) }}
      .upc(v-if="showUpc") {{ data.upc }}
      .info-variant__name(v-if="showVariantName" v-text="name")
      .info-variant__id(v-if="showVariantId"
        v-tooltip="'Item code is a unique identifier for SKUs that helps you search products and order with ease.'") # {{ variantId }}
      .info-variant__cases-per-master-carton(v-if="showCasesPerMasterCarton")
        span Cases per Master Carton:&nbsp;
          strong {{ data.cases_per_master_carton || 'N/A' }}
      .info-variant__state(v-if="showState")
        status-tag(:type="data.state")
      .upc(v-if="showUnitUPC")
        strong Unit UPC / EAN: {{ data.barcode_code }}
      .upc(v-if="showCaseUPC")
        strong Case UPC / EAN: {{ data.barcode_case_code }}
      .pack
        span.mr-1(v-if="showUnitPrice") {{ unitPrice }}/unit
        span.case-unit(v-if="showCaseUnits") {{ caseUnits | number }} {{ caseUnits > 1 ? 'units' : 'unit' }}/case
      .price(v-if="showPrice")
        span.current(:class="{promoted: !!promotions.length}") {{ data.case_price_cents | promotion-price(promotions[0]) }}
        span.old(v-if="promotions.length") {{ data.case_price_cents | cents }}
        price-change-stamp.ml-1(v-if="showPriceChange" :variant="data") &nbsp;
      ExpressTag.express(v-if="showBadge && data.is_warehoused_variant" v-tooltip="POD_EXPRESS_EXPLANATION")
      GrowTag.grow(v-else-if="showBadge && !hidePodGrow && isPodGrow" v-tooltip="POD_GROW_EXPLANATION")
      template(v-if="showCartable")
        .cartable.not-available(v-if="data.cartable === false") This flavor is not available

  client-only(v-if="showFavorite")
    button.favorite(
      v-if="$store.getters['authentication/isBuyer']"
      @click.stop="favorite"
      v-tooltip="favorited ? 'Remove from favorites' : 'Favorite'"
      :disabled="saving")
      .heart(:class="{animated: favorited}")

  .order-code(v-if="showCode") \#{{ data.id }}
</template>

<script>
import { mapGetters } from 'vuex'
import { POD_EXPRESS_EXPLANATION, POD_GROW_EXPLANATION, REGION_TYPES } from '~/settings'
import PriceChangeStamp from '~/components/stamps/PriceChangeStamp'
import PromotionTag from '~/components/tags/PromotionTag'
import ExpressTag from '~/components/tags/ExpressTag'
import GrowTag from '~/components/tags/GrowTag'

export default {
  components: {
    PriceChangeStamp,
    PromotionTag,
    ExpressTag,
    GrowTag
  },
  props: {
    data: {
      required: true,
      type: Object
    },
    showImage: { type: Boolean },
    selected: { type: Boolean },
    showPrice: { type: Boolean },
    readOnly: { type: Boolean },
    showBadge: { type: Boolean },
    hidePodGrow: { type: Boolean },
    showBrand: { type: Boolean },
    showBrandUrl: { type: Boolean },
    showProduct: { type: Boolean },
    showProductUrl: { type: Boolean },
    showVariant: { type: Boolean },
    showVariantUrl: { type: Boolean },
    showVariantName: { type: Boolean },
    showUpc: { type: Boolean },
    showUnitUPC: { type: Boolean },
    showCaseUPC: { type: Boolean },
    showCode: { type: Boolean },
    showCaseUnits: { type: Boolean },
    showUnitPrice: { type: Boolean },
    showPriceChange: { type: Boolean },
    showPromotion: { type: Boolean },
    showFavorite: { type: Boolean },
    showState: { type: Boolean },
    showVariantId: { type: Boolean },
    clickableImage: { type: Boolean },
    showCartable: { type: Boolean },
    showVariantRegions: { type: Boolean },
    showCasesPerMasterCarton: { type: Boolean },
    trackProps: { type: [Object, String], default: '' },
    asLink: { type: Boolean, default: false }
  },
  data () {
    return {
      favorited: null,
      saving: false,
      REGION_TYPES,
      POD_EXPRESS_EXPLANATION,
      POD_GROW_EXPLANATION
    }
  },
  computed: {
    ...mapGetters({
      apiRole: 'authentication/apiRole',
      isBuyer: 'authentication/isBuyer',
      isHeadBuyer: 'authentication/isHeadBuyer'
    }),
    caseUnits () {
      const { case_units, product_variant_case_units } = this.data
      return case_units || product_variant_case_units
    },
    imageUrl () {
      const { image_urls, image_url, product_variant_small_image_url, primary_image_url, photo_url, logo_url, sku_image_url, product_image_url } = this.data
      return image_urls?.thumbnail || image_url || product_variant_small_image_url || primary_image_url || photo_url || logo_url || sku_image_url || product_image_url
    },
    promotions () {
      if (!this.data) return []
      return this.data.promotions || []
    },
    name () {
      return this.data.product_variant_name
        ? this.data.product_variant_name
        : this.data.name
    },
    unitPrice () {
      return this.data.unit_price_str ? this.data.unit_price_str : this.data.unit_price
    },
    variantId () {
      return this.data.product_variant_id || this.data.id || this.data.variants_region_id
    },
    isPodGrow () {
      return this.data.is_pod_grow_variant
    }
  },
  watch: {
    'data.favorite_products_id': {
      immediate: true,
      handler (val) {
        this.favorited = val
      }
    }
  },
  methods: {
    favorite () {
      if (!this.isBuyer && !this.isHeadBuyer) return

      this.saving = true

      const payload = this.favorited || this.data.id
      const service = this.favorited
        ? this.$api[this.apiRole].removeFavoriteItem
        : this.$api[this.apiRole].addItemToFavorites

      service(payload)
        .then((res) => {
          this.favorited = this.favorited ? null : res.id
        })
        .finally(() => {
          this.saving = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.product-variant-stamp {
  position: relative;
  background: white;
  border: $--border-light;
  border-radius: $--radius;
  overflow: hidden;
  font-size: 13px;
  &.plain {
    border: none;
    border-radius: none;
    .image {
      padding: 0;
      margin-right: 1em;
      align-self: center;
    }
  }
  &.selectable {
    cursor: pointer;
  }
  &.selected {
    border: 1px solid $--color-primary;
    .order-code {
      background: $--color-primary;
    }
  }
  .image {
    min-width: 64px;
    flex: 0 64px;
    padding: 6px;
    align-self: center;
    .contain {
      width: 100%;
      height: 0;
      padding-top: 100%;
      border-radius: $--radius;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  .preview {
    padding: 6px 6px 6px 2px;
    flex: 1;
    .name {
      color: $--color-text-regular;
      font-size: 13px;
    }
    .price {
      .current {
        font-weight: $--font-weight-bold;
        font-size: 13px;
        &.promoted {
          color: $--color-primary;
        }
      }
      .old {
        font-size: 11px;
        color: $--color-text-secondary;
        text-decoration: line-through;
        margin-left: 8px;
      }
    }
    .pack {
      color: $--color-text-secondary;
      margin: 2px 0;
    }
    .cartable {
      margin-top: .5em;
      &.not-available {
        color: $--color-danger;
      }
    }
  }
  .express {
    // position: absolute;
    // right: 5px;
    // bottom: 25px;
    margin-top: 5px;
  }
  .order-code {
    color: white;
    background: #7d839a;
    font-size: 11px;
    padding: 3px 5px;
    font-weight: $--font-weight-bold;
    position: absolute;
    right: 0;
    bottom: 0;
    border-top-left-radius: $--radius;
  }
  .favorite {
    outline: none;
    appearance: none;
    border: none;
    background: transparent;
    font-size: 11px;
    height: 24px;
    width: 24px;
    position: absolute;
    left: 3px;
    bottom: 1px;
    transition: opacity .2s ease-in-out;
    &[disabled] {
      opacity: .25;
    }
    .heart {
      zoom: 1;
      width: 50px;
      height: 50px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: url(/img/heart.purple.webp) no-repeat;
      cursor: pointer;
      &.animated {
        background-position: -1400px 0;
        transition: background 1s steps(28);
      }
    }
  }
  .blocks {
    > * {
      display: inline-block;
      &:not(:last-child) {
        margin-right: 0.9em;
      }
    }
    .region {
      min-width: 125px;
    }
    .original-price {
      &.line-through {
        text-decoration: line-through;
      }
    }
    .discount-price {
      font-weight: 700;
      color: $--color-primary;
    }
  }
  .info-variant {
    &__id {
      width: max-content;
      color: black;
      background: white;
      font-size: 11px;
      padding: 3px 5px;
      margin: 4px 0;
      border-radius: $--radius;
      border: $--border-light;
    }
  }
}
</style>
