<template lang="pug">
el-dialog(
  custom-class="page__dialog buyer-order-feedback-modal"
  visible
  :close-on-click-modal="false"
  @close="close")
  .frame
    .frame__close(@click="close")
      i.bx.bx-x
    iframe(
      :src="src"
      width="100%"
      height="960"
      style="border: 0")
</template>

<script>
import { BUYER_ORDER_FEEDBACK_IFRAME_URL } from '~/settings'

export default {
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    src () {
      const params = new URLSearchParams({
        r: 'use1', // hardcoded, based on original form link
        email: this.$store.getters['authentication/user']?.email,
        order_id: this.order?.id
      })
      return `${BUYER_ORDER_FEEDBACK_IFRAME_URL}?${params.toString()}`
    }
  },

  methods: {
    close () {
      if (this.showFooter) this.$router.push({ name: 'products' })?.catch(this.$debug.log)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .buyer-order-feedback-modal {
    max-width: 640px;
    overflow: hidden;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      font-size: 0;
    }
    .el-dialog__footer {
      display: none;
    }
  }
}
.frame {
  display: relative;
  &__close {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    background: rgba(black, .5);
    border-radius: 0 0 0 8px;
    width: 36px;
    height: 36px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    &:hover {
      background: rgba(black, .75);
    }
  }
}
</style>
