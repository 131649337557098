<template lang="pug">
el-dialog.lg.weekly-promo-modal(visible @close="close" :show-close="false")
  .flex.jcfe
    a.promo__close(href="#" @click.prevent="close")
      i.bx.bx-x
  .promo
    .promo__wrapper(v-if="weeklyPromo")
      img.promo__image(:src="weeklyPromo.image_url")
      nuxt-link.promo__tag(
        v-for="t in weeklyPromo.tagged_products"
        :key="t.id"
        :title="t.name"
        :to="$links.product({ id: t.product_id, name: t.name, brand: t.brand_name })"
        :style="getStyle(t)"
        v-click="() => $amplitude.trackProduct({zone:'WEEKLY_PROMO', product_id: t.product_id})")
    .tc(v-else)
      p No weekly promotion for now!
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      weeklyPromo: 'master/weeklyPromo'
    })
  },

  watch: {
    $route () {
      this.$emit('close')
    }
  },

  methods: {
    close () {
      this.$emit('done', this.hidden)
      this.$emit('close')
    },
    getStyle (tag) {
      return {
        top: `${+tag.y_position * 100}%`,
        left: `${+tag.x_position * 100}%`,
        width: `${+tag.width * 100}%`,
        height: `${+tag.height * 100}%`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.weekly-promo-modal {
  padding: 0;
  ::v-deep {
    .el-dialog {
      position: relative;
      border-radius: $--radius;
      @include media(xs-only) {
        max-width: 320px;
      }
      &__header {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        z-index: 2;
      }
      &__body {
        padding: 0;
      }
    }
  }
}
.promo {
  overflow: hidden;
  position: relative;
  z-index: 1;
  background: $--color-placeholder;
  border-radius: $--radius-sm;
  &__close {
    display: flex;
    z-index: 3;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background: rgba(#fff, 0.1);
    font-size: 32px;
  }
  &__image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  &__wrapper {
    position: relative;
    font-size: 0;
    overflow: hidden;
    border-radius: 2px;
  }
  &__tag {
    position: absolute;
    border: 2px solid transparent;
    transition: border-color .1s ease-in-out;
    box-sizing: border-box;
    border-radius: $--radius;
  }
}
</style>
