<template lang="pug">
  .view-shippo-rate.pf-break-word
    el-row(:gutter="30")
      el-col.flex(:md="18")
        .blocks
          .contain(v-image="rate.provider_image_75")
        .provider
          strong {{ rate.provider }}
          .label(v-if="rate.duration_terms") {{ rate.duration_terms }}
          .tracking-label
            a.linked(target="_blank" rel="noopener noreferrer" :href="transaction.label_url") Print Shipping Label
      el-col(:md="6")
        .tr
          strong Shipping Rate
          .label.price {{ rate.amount }} {{ rate.currency }}
    .tracking-delivery.flex
      .tracking-delivery-offset
      .tracking-delivery-info
        el-row(:gutter="30")
          el-col(:md="12")
            strong Delivery Date
            .label(v-if="transaction.shipment_eta") {{ transaction.shipment_eta | date }}
            .label(v-else) N/A
          el-col(:md="12")
            strong Tracking Number
            .label {{ transaction.tracking_number }}
        el-row(:gutter="30")
          el-col(:md="12")
            strong Tracking Status
            .label
              tracking-tag(:type="transaction.tracking_status")
          el-col(:md="12")
            strong Parcel Information
            .label Size: {{ shipment.parcels | shippoParcelSize }}
            .label Weight: {{ shipment.parcels | shippoParcelWeight }}
        el-row(:gutter="30")
          el-col(:md="12")
            strong Address From
            .label {{ shipment.address_from | address}}
          el-col(:md="12")
            strong Address To
            .label {{ shipment.address_to | address }}
</template>
<script>
export default {
  props: {
    shippo: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    rate () {
      return this.shippo.rate || {}
    },

    shipment () {
      return this.shippo.shipment || {}
    },

    transaction () {
      return this.shippo.transaction || {}
    }
  }
}
</script>

<style lang="scss" scoped>
.view-shippo-rate {
  .tracking-delivery-offset {
    width: 80px;
  }

  .tracking-delivery-info {
    flex: 1;
  }

  .el-col {
    margin-bottom: 12px;
  }

  .price {
    color: $--color-primary;
    font-weight: bold;
  }

  .text-right {
    text-align: right;
  }

  .blocks {
    margin-bottom: .5em;
    .contain {
      border-radius: $--radius;
      background-repeat: no-repeat;
      width: 75px;
      height: 0;
      padding-top: 13px;
      vertical-align: top;
      margin: 5px 5px 0 0;
    }
    > * {
      display: inline-block;
    }
  }

  .provider {
    flex: 1;
  }
}

</style>
