<template lang="pug">
el-dialog(
  custom-class="page__dialog order-placed-modal"
  visible
  :close-on-click-modal="false"
  @close="close")

  .page__dialog-header.page__dialog-header--separated(slot="title")
    .page__dialog-preview
      i.page__dialog-icon.bx.bxs-check-circle

    .page__dialog-info
      .page__dialog-title Thank you for your order!
      .page__dialog-description Your order has been placed.

  .page__dialog-animation
    lottie(:options="animation")

  .notice
    strong All orders are final.
    | &nbsp; For order additions, please place a new order. Estimated delivery times can be found in your dashboard.

  .page__dialog-footer(v-if="showFooter" slot="footer")
    .actions
      el-button(@click="view" v-if="this.order") View order
      el-button(
        type="primary"
        @click="shopping") Continue shopping
    .feedback
      span(@click.prevent="feedback")
        strong Share your experience with us
        i.bx.bx-chevron-right
</template>

<script>
import BuyerOrderFeedbackModal from './BuyerOrderFeedbackModal'
import Lottie from 'vue-lottie'

export default {
  components: {
    Lottie
  },

  props: {
    order: {
      type: Object,
      default: () => ({})
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    animation () {
      return {
        animationData: require('~/assets/lotties/order-placed.json'),
        animationSpeed: 1,
        loop: true,
        autoplay: 1
      }
    }
  },

  methods: {
    view () {
      this.$router.push({
        name: 'buyers-orders-id',
        params: { id: this.order.id }
      })?.catch(this.$debug.log)
      this.$emit('close')
    },

    feedback () {
      this.$amplitude.track('OPEN_BUYER_FEEDBACK_FORM', {
        order_id: this.order?.id
      })
      return this
        .$dialogs
        .open({
          component: BuyerOrderFeedbackModal,
          parent: this,
          props: {
            order: this.order
          }
        })
    },

    shopping () {
      this.$router.push({ name: 'products' })?.catch(this.$debug.log)
      this.$emit('close')
    },

    close () {
      if (this.showFooter) this.$router.push({ name: 'products' })?.catch(this.$debug.log)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .order-placed-modal {
    max-width: 520px;
    overflow: hidden;
  }
}
.page__dialog-icon {
  color: $--color-success;
  background: none;
  font-size: 36px;
}
.notice {
  line-height: 1.5em;
  margin: 0 0 .5em 0;
  text-align: center;
}
.actions {
  text-align: center;
}
.feedback {
  margin: .75em 0 -0.5em 0;
  text-align: center;
  span {
    color: $--color-primary;
    display: inline-flex;
    align-items: center;
    font-weight: $--font-weight-bold;
    padding: 1em;
    cursor: pointer;
    i {
      font-size: 1.5em;
    }
  }
}
</style>
