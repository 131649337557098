<template lang="pug">
section.search-section.trending-keywords(v-if="records.length")
  .search-section__content
    nuxt-link(
      v-for="i, k in records"
      :key="k"
      :to="getRoute(i)"
      v-click="() => enter(i, k)")
      .stamp
        .stamp__icon
          i.bx.bx-trending-up
        .stamp__info
          .stamp__title(v-text="i")
        .stamp__type Trending in {{ month }}
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { serializeQuery } from '~/utilities/catalog'
import moment from 'moment'
import section from './section'

export default {
  mixins: [section],

  computed: {
    ...mapGetters({
      records: 'configs/searchTrendingKeywords'
    }),

    month () {
      return moment().format('MMM')
    }
  },

  methods: {
    ...mapMutations({
      clear: 'history/clearBrands'
    }),

    getRoute (term) {
      return {
        name: 'products',
        query: serializeQuery({
          term
        })
      }
    },

    enter (item, index) {
      this.track({ type: 'trending-keyword', keyword: item, position: index })
      this.collapse()
    }
  }
}
</script>

<style lang="scss" scoped>
.trending-keywords {
  $hover: darken(#f5eaf0, 2);
  .search-section__content {
    padding: 0 !important;
    > a {
      display: block;
      padding: 6px 16px;
      color: rgba($--color-text-regular, .8);
      &:hover, &[selected] {
        background: $hover;
      }
      strong {
        color: $--color-text-regular;
      }
    }
  }
  .stamp {
    display: flex;
    gap: 8px;
    align-items: center;
    > * {
      min-width: 0;
    }
    &__icon {
      flex: 0 24px;
      font-size: 16px;
    }
    &__info {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
    }
    &__type {
      text-align: right;
      font-size: 12px;
    }
  }
}
</style>
